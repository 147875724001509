/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.div300 {
    height: 300px;
    width: 300px;
    border: 1px solid brown;
}

.h300 {
    height: 300px;
    border: 1px solid brown;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.icon {
    height: 50px;
    width: 50px;
    border: 1px solid black;
}

.vh-100 {
    height: 100vh;
}

.vw-100 {
    width: 100vw;
}

.cursor-pointer {
    cursor: pointer;
}

.loader-sizing {
    max-height: 18px;
    max-width: 18px;
    font-size: 0.75rem;
}

.transition-upload {
    transition: color 0.3s, background-color 0.3s;
}

.fw-bold  {
    font-weight: 800 !important;
}

.fw-normal {
    font-weight: normal !important;
}

.fw-semibold {
    font-weight: 600 !important;
}
.loader-sizing {
    max-height: 18px;
    max-width: 18px;
    font-size: 0.75rem;
}

.image-object-contain {
    object-fit: contain;
    object-position: center;
}

.bg-primary {
    background-color: #0C356A !important;
}

.bg-new-secondary {
    background-color: #0174BE !important;
}

.bg-tertiary {
    background-color: #0C356A !important;
}

.bg-new-danger {
    background-color: #C70039 !important;
}

.bg-new-success {
    background-color: #116D6E !important;
}

.bg-new-warning {
    background-color: #FFC436 !important;
}

.shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

@media (max-width:576px) {

    .input-mobile {
        padding: 10px;
        font-size: 1rem;
    }
}

.table>:not(caption)>*>* {
    padding: 0
}


.table tfoot {
    font-size: .8125rem;
}

.table thead th {
    padding: 0;
}

.challan-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media print {
    body {
      margin: 0;
      -webkit-print-color-adjust: exact; /* For WebKit-based browsers like Chrome and Safari */
      color-adjust: exact; /* For Firefox and other browsers */
    }

    .print-section {
        box-sizing: border-box;
        width: 210mm !important;
        height: 297mm !important;
        page-break-before: always;
        position: relative;
        display: flex !important;
        flex-wrap: wrap;
    }

    .print-div {
        width: 50% !important;
        height: 50% !important;
    }

    .display-only-noPrint {
        display:  block !important;
    }
}

.display-only-noPrint {
    display: none;
}

.print-section {
    box-sizing: border-box;
    position: relative;
    display: flex !important;
    flex-wrap: wrap;
}

.print-div {
    width: 50%;
    height: auto;
    padding: 10px;
    margin-bottom: 10px;
    padding-bottom: 0;
}

@media (max-width:576px) {

    .print-section {
        box-sizing: border-box;
        position: relative;
        display: block;
    }

    .print-div {
        width: 100%;
        height: auto;
    }
}

.price-list-input {
    width: 100%;
    background: none;
    text-transform: uppercase;
    border-right: 1px solid black;
}

.add-new-category {
    width: 100%;
    cursor: pointer;
}

.min-vh-30 {
    min-height: 30vh;
}

.price-list-floation-btn {
    position: fixed;
    bottom: 16px;
    right: 16px;
    display: flex;
    align-items: end;
    justify-content: end;
    flex-direction: column;
    gap: 10px;
}

@media print {
    body {
        margin: 0;
        -webkit-print-color-adjust: exact; /* For WebKit-based browsers like Chrome and Safari */
        color-adjust: exact; /* For Firefox and other browsers */
      }

      .price-list-print-page {
        visibility: visible !important;
        display: block !important;
        width: 210mm !important;
        position: relative !important;
    }
  
      .cover-page,.last-page {
          box-sizing: border-box;
          width: 210mm !important;
          height: 305mm !important;
          position: relative;
      }

      .content-page {
        page-break-before: always;
        page-break-after: always;
      }
}

.price-list-print-page {
    /* display: none; */
    visibility: hidden;
    position: fixed;
}

.cover-page,.last-page {
    box-sizing: border-box;
    width: 210mm !important;
    height: 305mm !important;
    position: relative;
    z-index: 2;
    background-position: 100% 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.cover-page p {
    position: absolute;
    font-size: 1.5rem;
    top: 20px;
    left: 20px;
    color: white !important;
}

.cover-page .price-list-details {
    position: absolute;
    font-size: 1.5rem;
    bottom: 100px;
    right: 50px;
    color: white !important;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
    gap: 10px;
}

.cover-page .price-list-details h1 {
    color: #FFF;
    text-align: center;
    font-size: 45.167px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.cover-page .price-list-details span {
    color: #FADFB5;
    text-align: right;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content-page {
    display: flex;
    align-items: start;
    justify-content: end;
}

.content-page .left-side-branding {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    height: 305mm;
    width: 80mm;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: end;
    justify-content: center;
}

.left-side-branding img {
    width: 70%;
    margin-bottom: 70px;
}


.vertical-text {
    padding: 30px;
    transform: rotate(180deg);
    writing-mode: vertical-rl; /* vertical right-to-left */
    text-orientation: mixed;   /* optional, for better browser support */
}

.vertical-text h1 {
    color: #F0E8C2 !important;
    font-size: 5rem;
    font-weight: bold;
    font-family: serif;
}

.vertical-text p {
    color: #F0E8C2 !important;
}

.content-page .right-side-content {
    width: 120mm;
    z-index: 3;
}

.brand-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
}

.brand-name {
    color: #FB3640 !important;
    font-family: var(--x-font-serif);
    font-size: 1.5rem;
    font-weight: bold;
    width: 100%;
    margin-bottom: 5px;
}

.price-list-items,p {
    color: black !important;
}

.dividing-div {
    margin: 30px 0;
    height: 5px;
    width: 90%;
    background-color: #FB3640;
    align-self: flex-start !important;
}

.footer-note-price-list {
    margin-top: 50px;
}

.dropdown-menu-dark {
    border: none !important;
}
/* 
.analytics-card {
    position: relative;
} */
/* 
.analytics-card img {
    filter: blur(2px);
    -webkit-filter: blur(2px);
} */

.analytics-card p {
    background-color: black;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 1rem;
    color: white !important;
    width: 100%;
}

.hover-bg-dark {
    transition: none;
}

.hover-bg-dark:hover {
    background-color: #0174BE !important;
    color: white !important;
}

.accounts-autocomplete {
    width: 100%;
}