[data-bs-theme=bill] {
    --bs-primary: #D83F31;
}

[data-bs-theme=performa] {
    --bs-primary: #0C356A;
}

.billing-system .input-billing{
    width: 100%;
    margin-top: 1rem;
    background-color: #D9D9D9;
    padding: 5px;
}

.billing-system .text-dark {
    color: black !important;
}

.billing-system h1,
.billing-system h2,
.billing-system h3,
.billing-system h4,
.billing-system h5,
.billing-system h6 {
    font-weight: bold;
    color: var(--x-primary);
}


.billing-items .item-header {
    margin-top: 20px;
    box-sizing: border-box;
    width: 100%;
    background-color: black;
    color: white;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 5px 0;
    flex-wrap: wrap;
}

.billing-items .item-header .item-header-name{
    width: 50%;
    display: flex;
    justify-content: start;
    padding: 3px;
}

.billing-items .item-header .item-header-hsn{
    width: 13%;
    display: flex;
    justify-content: start;
    padding: 3px;
}

.billing-items .item-header .item-header-qty{
    width: 10%;
    display: flex;
    justify-content: start;
    padding: 3px;
}

.billing-items .item-header .item-header-price{
    width: 12%;
    display: flex;
    justify-content: start;
    padding: 3px;
}

.billing-items .item-header .item-header-amount{
    width: 15%;
    display: flex;
    justify-content: start;
    padding: 3px;
}


.billing-items .item-row {
    margin-top: 0;
    box-sizing: border-box;
    width: 100%;
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
}

.billing-items .item-row .item-header-name{
    width: 50%;
    display: flex;
    justify-content: start;
    padding: 3px;
    border-right: 1px solid black;
}

.billing-items .item-row .item-header-hsn{
    width: 13%;
    display: flex;
    justify-content: start;
    padding: 3px;
    border-right: 1px solid black;
}

.billing-items .item-row .item-header-qty{
    width: 10%;
    display: flex;
    justify-content: start;
    padding: 3px;
    border-right: 1px solid black;
}

.billing-items .item-row .item-header-price{
    width: 12%;
    display: flex;
    justify-content: start;
    padding: 3px;
    border-right: 1px solid black;
}

.billing-items .item-row .item-header-amount{
    width: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
}

@media only screen and (max-width: 576px) {
    /* For mobile phones: */
    .billing-items .item-header{
        display: none;
    }
    .billing-items .item-header .item-header-name{
        width: 100%;
    }
    
    .billing-items .item-header .item-header-hsn{
        width: 25%;
    }
    
    .billing-items .item-header .item-header-qty{
        width: 15%;
    }
    
    .billing-items .item-header .item-header-price{
        width: 20%;
    }
    .billing-items .item-header .item-header-amount{
        width: 40%;
    }

    .billing-items .item-row {
        margin-top: 15px;
    }

    .billing-items .item-row .item-header-name{
        border: 1px solid black;
        width: 100%;
    }
    
    .billing-items .item-row .item-header-hsn{
        width: 25%;
    }
    
    .billing-items .item-row .item-header-qty{
        width: 15%;
    }
    
    .billing-items .item-row .item-header-price{
        width: 20%;
    }
    .billing-items .item-row .item-header-amount{
        width: 40%;
        justify-content: end;
    }
}

.billing-items .bg-item-light {
    background-color: #CCE1E2 !important;
}

.billing-items .bg-item-dark {
    background-color: #99C3C6 !important;
}

.billing-items .bg-item-new {
    background-color: #FDAFB3 !important;
}

.billing-items input {
    background: none !important;
    font-weight: 600;
}

.tax-category-label {
    margin: 0 !important;
}

.tax-category-label .css-ahj2mt-MuiTypography-root {
    font-family: var(--x-body-font-family) !important;
    font-weight: 600;
}

.discount-input {
    width: 30px;
}

/*  */


@media print {
    body {
        margin: 0;
        -webkit-print-color-adjust: exact; /* For WebKit-based browsers like Chrome and Safari */
        color-adjust: exact; /* For Firefox and other browsers */
      }

    .print-section-billing {
        box-sizing: border-box;
        width: 210mm !important;
        position: relative;
        display: block;
        padding: 0px !important;
        page-break-before: always !important;
    }

    .print-section-billing .bill-content {
        padding: 0px !important;
    }

}

.print-section-billing {
    min-width: 210mm;
    min-height: 297mm;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
}

.print-section-billing .bill-content {
    box-sizing: border-box;
    /* width: 210mm; */
    max-width: 240mm;
    min-height: 297mm;
    position: relative;
    color: black;
    padding: 30px;
}

.print-section-billing .invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.print-section-billing .invoice-header .logo-div img{
    margin-top: 10px;
    height: 100px !important;
    width: auto !important;
}

.print-section-billing .contact-details {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: end;
}

.print-section-billing .contact-details p {
    margin: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.print-section-billing .contact-details img {
    height: 15px;
}

.print-section-billing .bill-to {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 15px;
    gap: 5px;
}

.print-section-billing .bill-to p {
    margin: 0;
    font-size: 14px;
}

.print-section-billing .bill-to .invoice-number-div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: end;
}

.print-section-billing .bill-to .invoice-number-div p {
    width: max-content;
}

.print-section-billing .mode-of-transport {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: start;
    gap: 20px;
    font-size: 14px;
}

.print-section-billing .item-header {
    margin-top: 10px;
    box-sizing: border-box;
    width: 100%;
    background-color: black;
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 5px 0;
}

.print-section-billing .item-header .item-header-s-no{
    width: 7%;
    display: flex;
    justify-content: center;
    padding: 3px;
}

.print-section-billing .item-header .item-header-name{
    width: 55%;
    display: flex;
    justify-content: start;
    padding: 3px;
}

.print-section-billing .item-header .item-header-hsn{
    width: 10%;
    display: flex;
    justify-content: center;
    padding: 3px;
}

.print-section-billing .item-header .item-header-qty{
    width: 5%;
    display: flex;
    justify-content: end;
    padding: 3px;
}

.print-section-billing .item-header .item-header-price{
    width: 10%;
    display: flex;
    justify-content: end;
    padding: 3px;
}

.print-section-billing .item-header .item-header-amount{
    width: 10%;
    display: flex;
    justify-content: end;
    padding: 3px;
}


.print-section-billing .item-row {
    margin-top: 0;
    box-sizing: border-box;
    width: 100%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: start;
}

.print-section-billing .item-row .item-header-s-no{
    width: 7%;
    display: flex;
    justify-content: center;
    padding: 3px;
}

.print-section-billing .item-row .item-header-name{
    width: 55%;
    display: flex;
    justify-content: start;
    padding: 3px;
    text-transform: uppercase;
}

.print-section-billing .item-row .item-header-hsn{
    width: 10%;
    display: flex;
    justify-content: center;
    padding: 3px;
}

.print-section-billing .item-row .item-header-qty{
    width: 5%;
    display: flex;
    justify-content: end;
    padding: 3px;
}

.print-section-billing .item-row .item-header-price{
    width: 10%;
    display: flex;
    justify-content: end;
    padding: 3px;
}

.print-section-billing .item-row .item-header-amount{
    width: 10%;
    display: flex;
    justify-content: end;
    padding: 3px;
}

.print-section-billing .bg-item-dark {
    background-color: #EAEAEA !important;
}

.print-section-billing .total-tnc {
    display: flex;
    justify-content: space-between;
    break-inside: avoid;
}

.print-section-billing .total-tnc .words-tnc {
    width: 63%;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
}

.print-section-billing .total-tnc .words-tnc .num-to-words{
    text-transform: capitalize;
    margin-bottom: 20px;
    width: 100%;
}

.print-section-billing .total-tnc .words-tnc .amount-breakage {
    display: flex;
    gap: 30px;
    margin-bottom: 20px;
}

.print-section-billing .total-tnc .words-tnc .amount-breakage p {
    padding: 0;
    border: 0;
    text-align: start;
}

.print-section-billing .total-tnc .words-tnc .amount-breakage span {
    font-weight: normal;
}

.print-section-billing .total-tnc .words-tnc p {
    padding: 10px;
    border: 1px solid black;
    border-right: 0;
    text-align: center;
    font-weight: bold;
}

.print-section-billing .total-tnc .words-tnc ol {
    list-style: inside decimal;
    padding: 0;
    padding-right: 100px;
}

.print-section-billing .total-tnc .total-counter {
    width: 37%;
    color: white;
    font-size: 12px;
    /* background-color: var(--bs-primary); */
    height: fit-content;
}

.print-section-billing .total-tnc .total-counter .bg-primary{
    background-color: var(--bs-primary) !important;
}

.print-section-billing .qrcode-signature {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 20px;
    break-inside: avoid;
}

.print-section-billing .qrcode-signature .qr-div {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.print-section-billing .qrcode-signature button {
    font-size: 12px !important;
    padding: 5px 20px;
}

.print-section-billing .qrcode-signature p {
    margin: 0;
    font-size: 12px;
}

.print-section-billing .qrcode-signature .bank-details img {
    height: 60px;
}

.print-section-billing .qrcode-signature .signature-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    gap: 50px;
}